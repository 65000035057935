body {
  font-family: "Segoe UI", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;

  @include breakpoint-md {
    line-height: 1.33;
  }
}

.display {
  font-family: "Colour Sans", sans-serif;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 1;

  @include breakpoint-md {
    font-size: 4.5rem;
  }
}

h1,
.h1 {
  font-family: "Colour Sans", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
  animation: fadeIn;
  animation-duration: 1.2s;

  @include breakpoint-md {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  color: map-get($colors, "primary");
  font-family: "Colour Sans", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1;
  animation: fadeIn;
  animation-duration: 1.2s;

  @include breakpoint-md {
    font-size: 2.5rem;
  }
}

.uppercase {
  text-transform: uppercase;
}

h3,
.h3 {
  font-family: "Colour Sans", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2;

  @include breakpoint-md {
    font-size: 1.5rem;
    line-height: 1.33;
  }

  &--light {
    font-weight: 400;
  }
}

p {
  line-height: 1.5;
}

.muted {
  opacity: 0.5;
}
