/**
 * Variables
 */

$colors: (
  background: #ffffff,
  primary: #3c73a9,
  secondary: #dbdc62,
  accent: #dd713a,
  light-gray: #f5f5f5,
);
