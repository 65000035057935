.jobs {
  @include breakpoint-md {
    > .container {
      display: grid;
      @include cols-2;
      align-items: center;
    }
  }

  &__cta {
    margin-top: 4rem;
    margin-bottom: 5.675rem;
    display: flex;
    justify-content: center;
    @include breakpoint-md {
      justify-content: flex-start;
    }
  }

  @include breakpoint-md {
    &__content {
      padding-right: 8rem;
    }
  }

  &__images {
    // Swiper
    @include breakpoint-md {
      padding: 0 4rem;
      margin-right: calc(-50vw + 100%);
      max-width: 768px;
      .swiper {
        padding-right: 2rem;
      }
      .swiper-wrapper {
        height: 760px;
      }
      .swiper-slide {
        width: 100%;
      }
      .swiper-vertical > .swiper-scrollbar {
        max-height: 10rem;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }

    .swiper-horizontal > .swiper-scrollbar {
      max-width: 212px;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(196, 196, 196, 0.7);
    }
    @include breakpoint-md {
      .swiper-wrapper {
        height: 760px;
      }
      .swiper-vertical > .swiper-scrollbar {
        max-width: 212px;
        left: auto;
        right: 0;
      }
    }
  }

  &__image {
    img {
      height: 20rem;
      object-fit: cover;
      object-position: center center;
      margin-bottom: 2rem;
      &:first-of-type {
        margin-left: 1.75rem;
      }
      &:last-of-type {
        margin-right: 1.75rem;
      }
      @include breakpoint-md {
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

.language-selector {
  button {
    font-family: "Colour Sans";
    font-size: 1.5rem;
    cursor: pointer;
  }
  .active {
    color: map-get($colors, "accent");
    font-weight: bold;
    text-decoration: underline;
  }
}
