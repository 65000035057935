.jobs-header {
  &__actions {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
.content-disclaimer {
  padding-right: 4rem;
  font-size: 0.875rem;
  @include below-md {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@import "jobs-image-slider";
