.order-information {
  &__header {
    padding-bottom: 0;
  }

  &__grid {
    @include breakpoint-md {
      display: grid;
      @include cols-2;
      column-gap: 8rem;
    }
  }

  &__block {
    margin-bottom: 2rem;
  }

  &__title {
    font-family: "Colour Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.33;
    margin-bottom: 0;
    color: map-get($colors, "primary");
  }

  &__text {
    line-height: 1.5;

    a {
      color: map-get($colors, "accent");
      border-bottom: 1px solid map-get($colors, "accent");
      padding-bottom: 0.15rem;
    }
  }

  &__card {
    margin-top: 2rem;
    background: #ffffff;
    box-shadow: 0px 3.58484px 43.0181px rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    padding: 1.5rem;
    @include breakpoint-md {
      display: grid;
      @include cols-2;
    }
  }

  &__list {
    padding-left: 32px;
    margin-bottom: 0.5rem;

    li {
      line-height: 1.5;
    }

    &::before {
      content: "";
      background-size: 100% 100%;
      width: 18px;
      height: 20px;
      vertical-align: middle;
      margin-left: -32px;
      position: absolute;
    }

    &--address {
      &::before {
        background-image: url("../images/location.svg");
      }
    }
    &--mail {
      &::before {
        background-image: url("../images/mail.svg");
      }
    }
    &--phone {
      margin-bottom: 0;
      &::before {
        background-image: url("../images/phone.svg");
      }
    }
  }
}
