body {
  background-color: map-get($colors, "background");
}

img {
  max-width: 100%;
}

section {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  overflow-x: hidden;
  @include breakpoint-md {
    overflow-x: visible;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

img.rounded {
  border-radius: 1.25rem;
}
a {
  color: unset;
  text-decoration: unset;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.swiper-scrollbar-custom {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
  background-color: map-get($colors, "accent");
}

section.full-width-image {
  margin-top: 5rem;
  height: 680px;
  background-size: cover;
  background-position: center center;
  background-color: #ccc;
}

.m-hidden {
  display: none;
  @include breakpoint-md {
    display: block;
  }
}

.d-hidden {
  @include breakpoint-md {
    display: none;
  }
}

.page-watermark {
  position: absolute;
  width: 100vw;
  @include breakpoint-md {
    width: 50vw;
  }
  aspect-ratio: 649 / 802;
  background-size: 100% 100%;
  z-index: -1;
}

.search-results {
  &__none,
  &__found {
    padding-bottom: 2rem;
    @include breakpoint-md {
      padding-bottom: 4rem;
    }
  }

  &__item {
    padding-left: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid map-get($colors, "primary");

    h3 a {
      color: map-get($colors, "accent");
    }
  }
}

.event-subscribe-form {
  display: grid;
  gap: 1rem;
  &__cols-2 {
    gap: 1rem;
    display: grid;
    @include breakpoint-md {
      grid-template-columns: auto auto;
    }
  }
  input,
  textarea {
    width: 100%;
  }
  input,
  textarea {
    border: 1px solid map-get($colors, "primary");
    border-radius: 0.5rem;
    padding: 1rem;
  }
  p {
    margin: 0;
  }
  input[type="submit"] {
    margin-left: auto;
    width: auto;
    border-radius: 2rem;
    padding: 0.75rem 2rem;
  }
}
