.p-simple-content,
.p-404 {
  h1 {
    color: map-get($colors, "primary");
    text-transform: uppercase;
  }
  padding-top: 0;
  padding-bottom: 2rem;
  max-width: 768px;
  margin: 0 auto;

  ul,
  ol {
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    li {
      list-style-type: disc;
      padding: 0.25rem 0;
    }
  }
  ol li {
    list-style-type: decimal;
  }

  @include breakpoint-md {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }

  p {
    text-align: justify;
  }

  h3 {
    margin-bottom: 0.5rem;
    + p {
      margin-top: 0;
    }
  }
}

.p-404 {
  h1,
  p {
    text-align: center;
  }
  a {
    color: map-get($colors, "accent");
    border-bottom: 1px solid map-get($colors, "accent");
  }
}
