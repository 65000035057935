.partnerships {
  background-color: map-get($colors, "light-gray");
  @include breakpoint-md {
    > .container {
      display: grid;
      @include cols-2;
    }
  }

  &__content {
    @include breakpoint-md {
      padding-left: 8rem;
    }
  }

  &__link {
    margin-left: 2rem;
    display: flex;
    flex-wrap: wrap;
    font-family: "Colour Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;

    &-name {
      font-weight: 700;
    }
  }

  .swiper-wrapper {
    height: 480px;
  }
  .swiper-vertical > .swiper-scrollbar {
    left: 0;
    right: auto;
    height: 50%;
    top: 50%;
    transform: translateY(-50%);
  }
}
