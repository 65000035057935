.cdmo-services {
  &__title {
    text-align: center;
    margin-bottom: 4rem;
  }

  &__items {
    display: grid;
    @include breakpoint-md {
      @include cols-3;
    }
    gap: 1.5rem;
    align-items: flex-start;
  }

  &__card {
    background: #ffffff;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    &-header {
      height: 16em;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    &-title {
      font-family: "Colour Sans";
      font-weight: 700;
      font-size: 2rem;
      line-height: 1;
      text-align: center;
      color: map-get($colors, "primary");
      margin: 2rem 0;
      padding: 0 2rem;
      min-height: 2em;
    }

    &-list {
      padding: 0 1.625rem;
      margin-bottom: 1rem;
      max-height: 8rem;
    }

    &-item {
      padding: 0.5rem 0;
      line-height: 1.5;
      border-bottom: 1px solid rgba(18, 24, 31, 0.2);
      &:last-of-type {
        border-bottom: none;
      }
    }

    &-overlay {
      display: block;
      position: absolute;
      bottom: 0;
      border-bottom-left-radius: 1.25rem;
      border-bottom-right-radius: 1.25rem;
      left: 0;
      background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 128.46%);
      height: 8rem;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      cursor: pointer;
      button {
        pointer-events: none;
      }
      svg {
        margin-bottom: 1rem;
      }
    }
  }
}
