.side-effects {
  padding-top: 0;
  padding-bottom: 0;
  @include breakpoint-md {
    padding-top: 10.5rem;
    padding-bottom: 10.5rem;
    background-position: center center;
    background-size: cover;
  }

  &__card {
    background-color: map-get($colors, "light-gray");
    max-width: 744px;
    margin: 0 auto;
    padding-top: 4.5rem;
    padding-bottom: 4rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    @include breakpoint-md {
      background-color: #ffffff;
      border-radius: 1.25rem;
      padding: 2.5rem 3.25rem;
    }
  }

  &__title {
    margin-top: 0;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -1rem;
      left: -1.75rem;
      width: calc(100% + 2 * 1.75rem);
      height: 0.5rem;
      background-color: map-get($colors, "secondary");
      @include breakpoint-md {
        left: -3.25rem;
        width: calc(100% + 2 * 3.25rem);
      }
    }
  }

  &__text {
    margin-bottom: 2.5rem;
  }

  &__actions {
    display: flex;
    justify-content: center;
  }
}
