@font-face {
  font-family: "Colour Sans";
  src: url("../fonts/ColourSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Colour Sans";
  src: url("../fonts/ColourSans-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI.ttf");
  font-weight: 400;
  font-style: normal;
}
