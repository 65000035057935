.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0 2rem;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(0.75rem);
  z-index: 150;
  display: grid;
  place-items: center;
  text-align: center;

  h2 {
    margin-top: 0;
  }

  &__box {
    background-color: #fff;
    padding: 2rem;
    border-radius: 1.25rem;
    max-width: 540px;
  }

  &__actions {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    button {
      margin-bottom: 1rem;
      @include breakpoint-md {
        margin-bottom: 0;
      }
    }
  }
}
