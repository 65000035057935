.page-header {
  padding-top: 0rem;
  > .container {
    display: grid;
    @include breakpoint-md {
      @include cols-2;
      align-items: center;
    }
  }

  @include breakpoint-md {
    padding-top: 5rem;
  }

  .h3 {
    color: #000;
    margin-bottom: 0;

    + p {
      margin-top: 0;
    }
  }

  &__content {
    grid-row-start: 2;
    @include breakpoint-md {
      grid-row-start: auto;
      padding-right: 8rem;
    }
  }

  &__readmore {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-family: "Colour Sans";
    font-style: normal;
    font-weight: 700;
    color: map-get($colors, "accent");
    display: block;
    cursor: pointer;
  }

  &__extra-content {
    text-align: justify;
  }

  &__image {
    grid-row-start: 1;
    margin-left: -1.75rem;
    margin-right: -1.75rem;
    img {
      width: 100%;
      border-radius: 0;
    }
    @include breakpoint-md {
      grid-row-start: auto;
      margin-right: calc(-50vw + 100%);
      margin-left: 0;
      img {
        max-width: unset;
        width: 100%;
        min-height: 480px;
        max-height: 680px;
        object-fit: cover;
        object-position: center center;
        &.rounded {
          border-top-left-radius: 1.25rem;
          border-bottom-left-radius: 1.25rem;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
