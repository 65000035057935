.contact-information {
  background-color: map-get($colors, "light-gray");

  @include breakpoint-md {
    > .container {
      display: flex;
      > div {
        flex: 1;
      }
    }
  }

  &__country-title {
    color: map-get($colors, "primary");
  }

  &__item {
    padding: 0.25rem 0 0.25rem 32px;

    ul li {
      padding: 0.25rem 0;
    }

    &::before {
      content: "";
      background-size: 100% 100%;
      width: 18px;
      height: 20px;
      vertical-align: middle;
      margin-left: -32px;
      position: absolute;
    }

    &--mail {
      &::before {
        background-image: url("../images/mail.svg");
      }
    }
    &--phone-number {
      &::before {
        background-image: url("../images/phone.svg");
      }
    }
    &--location {
      &::before {
        background-image: url("../images/location.svg");
      }
    }
    &--post {
      &::before {
        background-image: url("../images/post.svg");
      }
    }
    &--business {
      &::before {
        background-image: url("../images/business-card.svg");
      }
    }
  }
}
