.footer {
  background-color: map-get($colors, "primary");
  padding: 1.5rem 0 1rem 0;

  @include breakpoint-md {
    padding-top: 2rem;
  }

  * {
    color: #ffffff;
  }

  &__grid {
    display: grid;
    @include cols-2;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 1rem;
    @include breakpoint-md {
      @include cols-4;
      margin-bottom: 2rem;
    }
  }

  &__logo-container {
    grid-column: span 2;
    @include breakpoint-md {
      grid-column: span 1;
    }
  }

  &__logo {
    background-color: #ffffff;
    padding: 1.5rem 0;
    margin-left: -1.75rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    margin-bottom: 2rem;
    margin-right: 5rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;

    img {
      width: 100%;
    }

    @include breakpoint-md {
      margin-left: calc(-50vw + 200%);
      padding-left: calc(50vw - 200%);
      margin-right: 0;
      padding-top: 2rem;
      padding-bottom: 1.5rem;
      padding-right: 4rem;
    }
  }

  &__email {
    font-family: "Colour Sans", sans-serif;
    font-weight: 700;
    font-size: 0.875rem;

    @include breakpoint-md {
      font-size: 1.5rem;
    }
  }

  &__linkedin {
    display: flex;
    margin-top: 1rem;
    li a {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
      font-size: 0.875rem;
    }

    &--desktop {
      display: none;
      @include breakpoint-md {
        display: flex;
        grid-column: span 4;
        justify-content: flex-end;
      }
    }
  }

  &__links {
    h3 {
      margin-bottom: 0;
    }
    &--info,
    &--menu {
      display: none;
      @include breakpoint-md {
        display: block;
      }
    }
    &--mobile {
      @include breakpoint-md {
        display: none;
      }
    }
  }

  &__item {
    padding: 0.25rem 0;
    @include breakpoint-md {
      padding: 0.5rem 0;
    }
  }

  &__link {
    font-size: 0.875rem;

    @include breakpoint-md {
      font-size: 1rem;
    }
  }

  &__legal {
    display: flex;
    padding: 0.5rem 0;
    justify-content: space-around;
    li a {
      font-size: 0.875rem;
      @include breakpoint-md {
        font-size: 1rem;
      }
    }
    @include breakpoint-md {
      display: none;
    }
  }
}
