.news {
  &__specifics--item {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
    align-items: center;
  }

  &__specifics--list {
    margin-bottom: 2rem;
  }
  .page-header {
    padding-bottom: 1rem;
    @include below-md {
      padding-bottom: 0;
    }

    .icon {
      height: 1.5rem;
      width: 1.5rem;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  &-main {
    padding-top: 0;
    padding-bottom: 0;

    &__content {
      h2 {
        font-size: 2rem;
        margin-bottom: 0rem;
      }
    }
  }
}
