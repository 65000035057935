.hcp {
  &__main-title {
    text-align: center;
    margin-bottom: 3.5rem;
  }

  &-product__category {
    position: relative;
    padding-bottom: 2rem;
    @include breakpoint-md {
      padding-bottom: 0;
    }
    &::after {
      content: "";
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      position: absolute;
      bottom: 1rem;
      background-color: map-get($colors, "accent");
      @include breakpoint-md {
        display: none;
      }
    }
  }
  @include breakpoint-md {
    &-product__column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &-product__card {
    @include breakpoint-md {
      padding: 2.625rem 3.25rem;
      background: #ffffff;
      box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.15);
      border-radius: 1.25rem;
      display: grid;
      @include cols-2;
      column-gap: 8rem;
    }

    &-title {
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      @include breakpoint-md {
        font-size: 2rem;
      }
    }

    &-subtitle {
      margin-top: 0;
      margin-bottom: 2rem;
      font-size: 1.25rem;
      display: inline-flex;
      @include breakpoint-md {
        border-bottom: 1px solid map-get($colors, "accent");
        font-size: 1.5rem;
      }
    }

    &-list {
      margin-bottom: 2rem;
    }

    &-item {
      margin-bottom: 1rem;
      max-width: 400px;
    }

    &-link {
      display: flex;
      background: #ffffff;
      box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      padding: 0.5rem 2rem;
      transition-duration: 200ms;
      &:hover {
        background-color: map-get($colors, "primary");
        color: #ffffff;
      }
      @include breakpoint-md {
        font-size: 1.5rem;
      }
    }
  }
}
