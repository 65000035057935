.container {
  padding: 0 1.75em;
  margin-left: auto;
  margin-right: auto;
  max-width: 80em;
  width: 100%;
}

.grid {
  display: grid;
}

/**
* Generates 12 grid columns and also responsive classes for each screen width
*/
@for $i from 1 through 12 {
  .cols-#{$i} {
    grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
  }
  .cols-sm-#{$i} {
    @include breakpoint-sm {
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }
  }
  .cols-md-#{$i} {
    @include breakpoint-md {
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }
  }
  .cols-lg-#{$i} {
    @include breakpoint-lg {
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }
  }
}

@mixin cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@mixin cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@mixin cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@mixin cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

@mixin cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

@mixin cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

@mixin cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

@mixin cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

@mixin cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

@mixin cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

@mixin cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

@mixin cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

/**
* Generates 4 grid-gap classes with each 1em difference up to 4em
*/
@for $i from 1 through 4 {
  .gap-#{$i} {
    grid-gap: #{$i}em;
  }
  .column-gap-#{$i} {
    grid-column-gap: #{$i}em;
  }
  .row-gap-#{$i} {
    grid-row-gap: #{$i}em;
  }
}
