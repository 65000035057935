.jobs-image-slider {
  &__image {
    &:first-of-type {
      padding-left: 1rem;
    }
    &:last-of-type {
      margin-right: 1rem;
    }
    img {
      margin-bottom: 2rem;
      width: 28rem;
      height: 18rem;
      object-fit: cover;
      object-position: center center;
    }
  }

  .swiper-scrollbar {
    max-width: 212px;
    left: 50% !important;
    transform: translateX(-50%);
    background: rgba(196, 196, 196, 0.7);
  }
}

.jobs {
  .swiper {
    margin-left: -1.75em;
    margin-right: -1.75em;
    @include breakpoint-md {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
