.courses {
  > .container {
    display: grid;

    @include breakpoint-md {
      @include cols-2;
      align-items: center;
    }
  }

  &__list {
    margin-top: 5rem;
  }

  &__item {
    margin-bottom: 0.75rem;
  }

  &__content {
    @include breakpoint-md {
      padding-left: 8rem;
    }
  }

  &__image {
    grid-row-start: 1;
    margin-left: -1.75rem;
    margin-right: -1.75rem;
    img {
      width: 100%;
      border-radius: 0;
    }
    @include breakpoint-md {
      grid-row-start: auto;
      margin-left: calc(-50vw + 100%);
      margin-right: 0;
      img {
        max-width: unset;
        width: 100%;
        min-height: 480px;
        max-height: 680px;
        object-fit: cover;
        object-position: center center;
        &.rounded {
          border-top-right-radius: 1.25rem;
          border-bottom-right-radius: 1.25rem;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    transition-duration: 200ms;
    border-bottom: 1px solid transparent;
    strong {
      font-family: "Colour Sans";
      font-style: normal;
      font-weight: 700;
    }
    &:hover {
      color: map-get($colors, "accent");
      border-bottom: 1px solid map-get($colors, "accent");
    }
    svg {
      margin-left: 1rem;
    }
  }
}
