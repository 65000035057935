.pdp-faq {
  &__main-title {
    text-align: center;
    margin-bottom: 4rem;
  }

  &__bg {
    background: #ffffff;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.15);
  }

  &__list {
    padding: 3rem 0;
  }

  &__question {
    border-bottom: 1px solid map-get($colors, "accent");
    padding: 1rem 0;
    &-title {
      font-family: "Colour Sans";
      font-weight: 400;
      font-size: 1.5rem;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      cursor: pointer;
      span {
        pointer-events: none;
      }
      svg {
        min-width: 1rem;
      }
    }
    &-answer {
      padding: 1rem;
      display: none;

      ul,
      ol {
        padding-left: 1.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        li {
          list-style-type: disc;
          padding: 0.25rem 0;
        }
      }
      ol li {
        list-style-type: decimal;
      }

      &--visible {
        display: block;
      }
    }
    &-chevron {
      &--is-open {
        transform: rotate(180deg);
      }
    }
  }
}
