.activities {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 4rem;
  background-size: cover;
  background-position: center center;

  @include breakpoint-md {
    padding-top: 7rem;
    padding-bottom: 4.75rem;
  }

  * {
    color: #ffffff;
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: linear-gradient(360deg, rgba(60, 115, 169, 0.92) 0%, rgba(60, 115, 169, 0.59) 100%);
  }

  &__content {
    position: relative;
    z-index: 10;
  }

  &__title {
    margin-bottom: 1.5rem;

    @include breakpoint-md {
      text-align: center;
      margin-bottom: 2.75rem;
    }
  }

  &__text {
    margin-bottom: 4rem;

    @include breakpoint-md {
      text-align: center;
      max-width: 462px;
      margin: 0 auto;
      margin-bottom: 3.5rem;
    }
  }

  &__readmore {
    display: block;
    margin-bottom: 2rem;
    @include breakpoint-md {
      text-align: center;
    }
  }

  @include breakpoint-md {
    &__list {
      display: flex;
      justify-content: space-between;
      max-width: 652px;
      margin: 0 auto;
    }
  }

  &__item {
    margin: 1.5rem 0;
  }

  &__link {
    font-family: "Colour Sans";
    font-weight: 700;
    border-bottom: 1px solid #fff;
    padding: 0.25rem 0;
    &::after {
      content: "";
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 16'%3E%3Cpath d='M24.707 7.293a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 0 1-1.414-1.414L22.586 8l-5.657-5.657A1 1 0 0 1 18.343.93l6.364 6.364ZM0 7h24v2H0V7Z' fill='%23fff'/%3E%3C/svg%3E");
      width: 1.5rem;
      position: relative;
      transform: translateY(25%);
      height: 1rem;
      margin-left: 0.5rem;
      display: inline-block;
    }
  }
}
