@import "contact-information";

.page-template-template-contact {
  .page-header {
    .container {
      @include below-md {
        display: block;
      }
    }
  }
}

.gform_heading {
  display: none;
}

.gform_confirmation_wrapper {
  margin-top: 2rem;
}

.gform_body {
  margin-top: 2rem;

  input,
  textarea {
    border: 1px solid map-get($colors, "primary");
    border-radius: 0.5rem;
  }
  select {
    border: 1px solid map-get($colors, "primary");
    border-radius: 0.5rem;
    margin-top: 2rem;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  .gfield,
  .ginput_complex {
    font-family: "Colour Sans";
    margin-top: 0.5rem;
  }

  #field_1_8 {
    button {
      @extend .button;
      display: block;
      font-family: "Colour Sans";
      color: white;
      font-weight: bold;
      width: max-content;
    }
  }
}
