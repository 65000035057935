.history {
  @include breakpoint-md {
    > .container {
      display: grid;
      @include cols-2;
      grid-column-gap: 7.5rem;
      align-items: center;
    }
  }

  background: linear-gradient(180deg, #f5f5f5 0%, rgba(245, 245, 245, 0) 100%);
  @include breakpoint-md {
    background-color: map-get($colors, "light-gray");
  }

  @include breakpoint-md {
    &__content {
      grid-column-start: 2;
    }
  }

  &__illustration {
    position: relative;
    max-height: 440px;
    overflow: hidden;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  &__readmore {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 128.46%);
    color: map-get($colors, "accent");
    font-family: "Colour Sans";
    font-style: normal;
    font-weight: 700;
    padding: 1rem;
    width: 100%;
    height: 120px;
    cursor: pointer;
    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }
    // @include breakpoint-md {
    //   background: linear-gradient(0deg, #f5f5f5 0%, rgba(0, 0, 0, 0) 128.46%);
    // }
  }
}
