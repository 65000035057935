.values {
  padding-top: 5rem;
  padding-bottom: 5rem;

  &__title {
    text-align: center;
  }

  &__illustration {
    margin-left: auto;
    margin-right: auto;
    max-width: 71.25em;
    img {
      width: 100%;
    }

    .swiper-slide {
      padding: 0 1.75rem;

      @include breakpoint-md {
        padding: 0;
      }
    }

    .swiper-scrollbar {
      max-width: 212px;
      left: 50% !important;
      transform: translateX(-50%);
      background: rgba(196, 196, 196, 0.7);
    }
  }
}
