.intro {
  @include breakpoint-md {
    padding-top: 8rem;
    padding-bottom: 8rem;
    > .container {
      display: grid;
      @include cols-2;
      align-items: center;
    }
  }

  &__content {
    margin-bottom: 4rem;
    @include breakpoint-md {
      max-width: 454px;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 4rem;
  }

  &__image {
    img {
      width: 100%;
    }
    @include breakpoint-md {
      margin-right: calc(-50vw + 100%);
      img {
        max-width: unset;
        width: 100%;
        max-height: 680px;
        object-fit: cover;
        object-position: center center;
        &.rounded {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  &__payoff {
    position: relative;
    color: map-get($colors, "primary");

    &::after {
      content: "";
      position: absolute;
      bottom: -25%;
      left: -1.75rem;
      width: calc(100% + 1.75rem);
      height: 0.5rem;
      background-color: map-get($colors, "secondary");
      @include breakpoint-md {
        z-index: -1;
        width: 100vw;
        left: calc(-50vw + 100%);
      }
    }
  }
}
