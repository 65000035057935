.header {
  padding-top: 1.4rem;
  padding-bottom: 1.1rem;
  position: relative;

  &__search {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 120;
    transform: translateY(-100%);
    transition: ease-in 300ms;

    &.is-open {
      transform: translateY(0);
    }

    > .container {
      height: 100%;
      position: relative;
    }

    &-trigger-close {
      position: absolute;
      right: 0.5rem;
      @include breakpoint-md {
        right: 1.75rem;
      }
      font-size: 2rem;
      cursor: pointer;
    }
  }

  &__search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    form {
      display: flex;
      align-items: center;
      justify-content: center;

      input[type="search"] {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0.75rem 1rem;
        @include breakpoint-md {
          padding: 0.75rem 2rem;
        }
        border-bottom: 1px solid map-get($colors, "primary");
        border-radius: 0;
        &::placeholder {
          color: map-get($colors, "accent");
        }
      }
      input[type="submit"] {
        @include breakpoint-md {
          margin-left: 1rem;
        }
      }

      .screen-reader-text {
        display: none;
      }
    }
  }

  &__nav {
    display: grid;
    align-items: center;

    @include cols-6;

    @include breakpoint-md {
      @include cols-8;
    }
  }

  @include breakpoint-md {
    &__trigger-menu {
      display: none;
    }
  }

  &__trigger-search {
    justify-self: flex-end;
    path.icon {
      stroke: map-get($colors, "primary");
    }
    button {
      cursor: pointer;
    }
  }

  &__list {
    display: none;
    white-space: nowrap;

    @include breakpoint-md {
      display: flex;
      justify-content: space-around;
      grid-column: span 5;
    }
  }

  &__logo {
    grid-column: span 4;
    @include breakpoint-md {
      grid-column: span 2;
    }
    img {
      @include breakpoint-md {
        width: 220px;
      }
      border-radius: 0;
    }
  }

  &__link {
    color: map-get($colors, "primary");
    &.link-active {
      color: map-get($colors, "accent");
      border-bottom: 1px solid map-get($colors, "accent");
      padding-bottom: 0.5rem;
    }
  }

  svg {
    width: auto;
    height: 1.5rem;
  }
}

.mobile-menu {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  padding: 2rem;
  transform: translateY(-100%);
  transition-duration: 300ms;

  @include breakpoint-md {
    display: none;
  }

  &.is-open {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transform: translateY(0);
  }

  &__topbar {
    display: flex;
    img {
      margin-left: 1rem;
    }
  }

  &__list {
    padding-top: 2rem;
  }

  &__item {
    padding: 1rem 0;
  }

  &__link {
    color: map-get($colors, "primary");
    font-size: 1.25rem;
  }
}
