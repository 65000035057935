.patient-products {
  margin-bottom: 5rem;
  h2 {
    text-align: center;
    margin-bottom: 4rem;
  }

  &__card {
    background: #ffffff;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    padding-top: 2.5rem;
    height: 100%;

    h3 {
      font-size: 2rem;
      color: map-get($colors, "primary");
      margin: 0;
      margin-bottom: 0;
    }
  }

  &__subtitle {
    line-height: 1.33;
    min-height: 2.66em;
    border-bottom: 1px solid map-get($colors, "accent");
    margin: 0;
    padding: 0.5rem 0;
  }

  &__text {
    padding: 1rem 0;
  }

  .swiper,
  .swiper-wrapper {
    overflow: hidden;
    @include breakpoint-md {
      overflow: visible;
    }
  }

  .swiper-slide {
    align-self: stretch;
    height: auto;
  }

  &__items {
    > div > div {
      display: grid;
      gap: 1rem;
      @include breakpoint-md {
        @include cols-3;
        gap: 2rem;
      }
    }
  }
}
