.p-course {
  padding: 2rem 0;
  background-color: map-get($colors, "light-gray");

  &__container {
    display: grid;
    @include breakpoint-md {
      @include cols-12;
    }
  }

  &__subscribe-title {
    font-family: "Colour Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-top: 2rem;
  }

  &__event-meta {
    @include breakpoint-md {
      grid-column: 5 span;
      padding-top: 8rem;
      padding-bottom: 8rem;
      padding-right: 4rem;
    }

    &-title {
      font-family: "Colour Sans";
      font-size: 2.5rem;
      text-transform: uppercase;
      color: map-get($colors, "primary");
      margin-bottom: 4rem;
    }

    &-list {
      //
    }

    &-item {
      font-family: "Colour Sans";
      font-weight: 400;
      font-size: 1.5rem;
      margin-bottom: 1.25rem;
      display: flex;
      align-items: center;
      padding-left: 32px;

      &::before {
        content: "";
        background-size: 100% 100%;
        width: 18px;
        height: 20px;
        vertical-align: middle;
        margin-left: -32px;
        position: absolute;
      }

      &--date {
        &::before {
          background-image: url("../images/date.svg");
        }
      }
      &--address {
        &::before {
          background-image: url("../images/location.svg");
        }
      }
      &--time {
        &::before {
          background-image: url("../images/time.svg");
        }
      }
      &--teacher {
        &::before {
          background-image: url("../images/speaker.svg");
        }
      }
    }
  }

  &__description {
    @include breakpoint-md {
      grid-column: 7 span;
      background: #ffffff;
      box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.15);
      border-radius: 60px;
      padding: 4rem;
    }
  }
}
