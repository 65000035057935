.hero {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint-md {
    justify-content: baseline;
    height: 35.75rem;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.2);
  }

  h1 {
    position: relative;
    text-align: center;
    color: #ffffff;
    z-index: 10;

    @include breakpoint-md {
      max-width: 34rem;
      text-align: left;
    }
  }
}
