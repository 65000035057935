button {
  background: transparent;
  border: none;
  outline: none;
}

.button,
.wpcf7-submit {
  padding: 0.75rem 2rem;
  color: #ffffff;
  background-color: map-get($colors, "primary");
  font-family: "Colour Sans";
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  border-radius: 5rem;
  border: 2px solid map-get($colors, "primary");
  transition-duration: 200ms;
  cursor: pointer;

  &--center {
    margin: 0 auto;
  }

  &--outline {
    background-color: transparent;
    color: map-get($colors, "primary");

    &:hover {
      background-color: map-get($colors, "primary");
      color: #ffffff;
    }
  }

  &--download {
    padding-left: 3rem;
    &::before {
      content: "";
      background-size: 100% 100%;
      width: 18px;
      height: 20px;
      vertical-align: middle;
      margin-left: -24px;
      position: absolute;
      background-image: url("../images/download.svg");
    }
  }
}
