.cdmo-footer {
  background-color: map-get($colors, "light-gray");
  margin-top: 5rem;
  @include breakpoint-md {
    margin-top: 0;
  }
  > .container {
    display: grid;
    @include breakpoint-md {
      @include cols-12;
      column-gap: 4.5rem;
    }
  }

  &__brochure {
    grid-row-start: 2;
    margin-top: 2rem;
    text-align: center;
    img {
      width: 100%;
    }
    @include breakpoint-md {
      text-align: left;
      margin-top: 0rem;
      grid-row-start: auto;
      grid-column: span 4;
    }

    &-text {
      font-size: 1rem;
      line-height: 1.33;
      @include breakpoint-md {
        font-size: 1.5rem;
      }
    }

    button {
      margin: 0 auto;
      @include breakpoint-md {
        margin: unset;
      }
    }
  }

  &__testimonials {
    @include breakpoint-md {
      grid-column: 5 / span 8;
      background: #ffffff;
      box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.15);
      border-radius: 60px;
      padding: 3rem 5rem;
    }

    &-title {
      font-family: "Colour Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      margin-top: 0;
    }

    &-services {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      li {
        margin-right: 1rem;
        font-family: "Colour Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        color: map-get($colors, "primary");
        @include breakpoint-md {
          margin-right: 3rem;
        }
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -1rem;
        left: -1.75rem;
        width: calc(100% + 2 * 1.75rem);
        height: 8px;
        background-color: map-get($colors, "secondary");
        @include breakpoint-md {
          left: -5rem;
          width: calc(100% + 10rem);
        }
      }
    }

    blockquote {
      font-family: "Colour Sans";
      font-size: 1.5rem;
      line-height: 1.33;
      margin: 0;
      margin-top: 4rem;
      position: relative;
      &::before {
        content: "\201C";
        position: absolute;
        left: -1rem;
        top: -1rem;
        font-size: 2rem;
        color: map-get($colors, "accent");
        @include breakpoint-md {
          left: -2rem;
          top: -2rem;
          font-size: 4rem;
        }
      }
      &::after {
        content: "\201D";
        position: absolute;
        bottom: 0rem;
        margin-left: 0.5rem;
        font-size: 2rem;
        color: map-get($colors, "accent");
        @include breakpoint-md {
          font-size: 4rem;
          bottom: -2rem;
        }
      }
    }

    &-author {
      display: block;
      text-align: right;
      font-family: "Colour Sans";
      font-weight: 700;
      font-size: 1.5rem;
    }
  }
}
