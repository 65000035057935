.media-center-part {
  padding-bottom: 10rem;
  @include below-md {
    padding-top: 0;
    padding-bottom: 6rem;
  }
}

.media-center {
  &__box {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    @include below-md {
      grid-template-columns: 1fr;
    }
  }

  &__menu {
    grid-column: span 2;
    @include below-md {
      grid-column: span 1;
    }
    &--list {
      @include below-md {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }
    &--item {
      &-btn {
        cursor: pointer;
        font-family: "Colour Sans";
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-transform: uppercase;
        font-size: 1.25rem;
        color: inherit;
        font-weight: 300;
        @include below-md {
          padding-left: 0;
        }
        &.active {
          font-weight: 700;
          color: #dd713a;
          text-decoration: underline;
        }
      }
    }
  }

  &__posts {
    grid-column: span 6;
    &--list:not(.active) {
      display: none;
    }
    &--item {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #dd713a;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-family: "Colour Sans";
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        margin-top: 0;
      }

      p {
        margin-top: 0rem;
        margin-bottom: 0;
      }
    }
  }
}
