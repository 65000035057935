.page-content {
  @include breakpoint-md {
    .cols-2 {
      display: grid;
      @include cols-2;
      column-gap: 8rem;
      align-items: center;
    }
  }
  h2 {
    color: map-get($colors, "primary");
  }
  &__image {
    @include breakpoint-md {
      margin-left: calc(-50vw + 100%);
      img {
        max-width: unset;
        width: 100%;
        max-height: 680px;
        object-fit: cover;
        object-position: center center;
        &.rounded {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
